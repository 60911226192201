import React from 'react'

const Loader2 = () => {
  return (
    <>
      <div class='outer'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 2307.97 411.29'
          class='trishamody'
        >
          <defs>
            <clipPath id='clip-path'>
              <rect id='rect' x='0' y='0' height='411.29' width='2307.97' />
            </clipPath>
          </defs>

          <g
            id='Layer_2_00000079473966681312878150000008130550272874370465_'
            fill='black'
            className='first-tm'
          >
            <g id='Layer_1-2'>
              <g>
                <path
                  d='M1526.8100586,330h-63.4100342c-0.0799561-1.8099976-0.2099609-3.4500122-0.2099609-5.0899963
				c0-45.8333435,0-91.6666718,0-137.5c0.0875244-6.5981903-0.8192139-13.171936-2.6899414-19.5
				c-3-9.6000061-9-16.6300049-18.4599609-20.5599976c-9.1300049-3.8000031-18.6500244-3.8000031-27.9300537-1.7700043
				c-8.8679199,1.9668121-16.5993652,7.3583374-21.5100098,15c-5.088623,7.6015778-7.7397461,16.5734711-7.6000977,25.7200012
				c0,46.1933289,0,92.383316,0,138.5699921c0,1.6400146-0.1500244,3.2799988-0.2399902,5.1199951h-61.0999756
				c-0.0699463-1.4599915-0.1999512-3-0.1999512-4.4599915c0-41.1466675,0-82.3000031,0-123.4599915
				c0-5-0.1500244-10.0800018,0-15.1100006c0.2235107-5.3820953-0.3151855-10.7687531-1.5999756-16
				c-2.7900391-11.2100067-9.1600342-19.3999939-20.1999512-23.7700043c-8.8499756-3.5-17.9300537-3.4199982-26.8599854-1.5500031
				c-6.793335,1.5662079-12.9411621,5.1828766-17.6102295,10.3600006c-6,6.3600006-9.1800537,14-11,22.4599915
				c-0.7598877,3.8753662-1.0952148,7.821991-1,11.7700043V330h-62.8800049
				c-0.7600098-2.5700073-1-227.8300018-0.2800293-233.5899963h63.1400146v38.7599945l0.6999512,0.2299957
				c0.5406494-0.4972992,1.0025635-1.0738831,1.3699951-1.7100067c3.4147949-8.9544067,8.7657471-17.0438232,15.670166-23.6899872
				c4.8123779-4.6363373,10.3439941-8.4625473,16.3800049-11.3300018
				c12.3405762-6.015007,26.1064453-8.4933395,39.7700195-7.1600037
				c13.6500244,1.3199997,26.4499512,5.4499969,37.1099854,14.6399994
				c4.5775146,3.8200226,8.5109863,8.351181,11.6500244,13.4199982c3.6668701,5.8577042,6.4532471,12.2223206,8.2700195,18.8899994
				c0.1906738,0.4581451,0.4250488,0.8968506,0.6999512,1.3099976c0.5739746-0.5723419,1.0654297-1.2219391,1.4599609-1.9299927
				c1.9450684-5.2593384,4.581543-10.2363815,7.8399658-14.8000031
				c9.0424805-12.7390137,21.9023438-22.271843,36.7199707-27.2200012c11.5699463-3.8799973,23.5699463-5.6500015,35.7700195-4.25
				c11.2199707,1.2799988,22.1300049,4,32,9.7900009c8.2915039,4.7622299,15.5064697,11.1906662,21.1899414,18.8799973
				c4.9282227,6.8850861,8.7602539,14.4919128,11.3599854,22.5500031c0.7210693,2.5334167,1.3051758,5.1038055,1.75,7.6999969
				c1.4476318,6.5680695,2.175293,13.2743073,2.1700439,20c0.0400391,51.7133331,0.0400391,103.4233398,0,155.1300049
				C1527.0699463,327,1526.9100342,328.3999939,1526.8100586,330z'
                />
                <path
                  d='M1983.1700439,136.0800018c0.0899658-6.6399994,0-13.2900009,0-19.9300003s0-13.4300003,0-20.1500015V15.5799999
				h63.6999512V330h-63.5999756c0-4.269989-0.0799561-8.5400085-0.0899658-12.8099976c0-4.4400024,0-8.8800049,0-13.3200073
				v-13.4100037c-1.5325928,0.980957-2.6993408,2.4394531-3.3199463,4.1499939
				c-4.2319336,8.5864258-10.2174072,16.190033-17.5699463,22.3200073c-12,10.1799927-25.9399414,15.6799927-41.5699463,17.480011
				c-9.7851562,1.0449219-19.6685791,0.708313-29.3599854-1c-8.0100098-1.4439392-15.7611084-4.0692444-23-7.7900085
				c-9.2861328-4.6867065-17.6154785-11.0660095-24.5600586-18.8099976
				c-4.9748535-5.6221619-9.2741699-11.8076477-12.8100586-18.4299927
				c-2.3834229-4.6491394-4.451416-9.4533081-6.1900635-14.3800049
				c-4.3996582-11.6364746-7.2116699-23.8126526-8.3599854-36.1999969c-1.0487061-9.0450592-1.5595703-18.1443939-1.5300293-27.25
				c0.0969238-12.3430328,1.3017578-24.6524353,3.5999756-36.7799988
				c2.3178711-12.3393707,6.463501-24.2639313,12.3000488-35.3800049
				c4.213623-8.0336609,9.5655518-15.4165649,15.8900146-21.9199982c4.2144775-4.3441162,8.9116211-8.1924133,14-11.4700012
				c7.3170166-4.7318726,15.3554688-8.2410965,23.8000488-10.3899994c10.0699463-2.4700012,20.2299805-4,30.5999756-3.1999969
				c5.1245117,0.4585419,10.2169189,1.2232437,15.25,2.2900009c6.5479736,1.3862,12.8513184,3.7457352,18.6999512,7
				c10.5999756,5.5941772,19.5919189,13.8081131,26.1199951,23.8600006c2.1199951,3.3099976,4,6.7600021,6,10.1299973
				c0.3592529,0.5316925,0.756958,1.036377,1.1899414,1.5099945L1983.1700439,136.0800018z M1879.7600098,215.4100037
				c0.0449219,4.4746857,0.3220215,8.9440155,0.8299561,13.3899994c0.7817383,5.3181305,1.9890137,10.5649719,3.6099854,15.6900024
				c2.4124756,8.2691803,6.6036377,15.9109344,12.2800293,22.3899994c4.4078369,5.0044861,9.8730469,8.9667053,16,11.6000061
				c9.4200439,4,19.3399658,4.5799866,29.2600098,2.6300049c11.0600586-2.1700134,20-8.1400146,27-17
				c7.6378174-9.9130554,12.3581543-21.7606049,13.6300049-34.2099915c1.6800537-14.4199982,1.8499756-28.8899994-2-43.1600037
				c-3.2700195-12.1600037-8.8000488-22.8899994-18.3399658-31.3300018c-5.4716797-5.064209-12.2320557-8.5240021-19.5400391-10
				c-9.3299561-1.6900024-18.7299805-1.6900024-28,1.7100067c-8.6690674,3.201767-16.1624756,8.9587097-21.4899902,16.5099792
				c-5.2399902,7.1799927-8.4399414,15.4299927-10.5100098,24.1000061
				C1880.4699707,196.0500031,1879.7600098,204.5,1879.7600098,215.4100037z'
                />
                <path
                  d='M676.9199829,15.5500002h63.5700073v30.1199989c0,10.0800018,0,20.159996,0,30.2399979
				c0,9.9599991,0,19.9199982,0,29.8799973s0,19.9599991,0,30l1,0.2799988c0.460022-0.6900024,1-1.3600006,1.3699951-2.0800018
				c2.3699951-4.1199951,4.4899902-8.4100037,7.1199951-12.3600006c3.4869995-5.1042099,7.6746826-9.6925049,12.4400024-13.6299896
				c6.2246704-5.2093811,13.3669434-9.211235,21.0599976-11.8000031C794.2352905,92.4305496,805.6157227,90.7669449,817,91.3000031
				c13.2999878,0.6500015,26,3.5,37.8099976,10c8.5597534,4.7560883,16.048584,11.2237015,22,19
				c7.710022,9.8699951,12.3099976,21.0899963,15.4199829,33.0699921
				c2.5100098,9.7100067,3.1400146,19.6300049,3.1500244,29.5800018c0.0665894,47.519989,0.0665894,95.0366669,0,142.5500031v4.5
				h-63.3200073c-0.1199951-1.6799927-0.3599854-3.4100037-0.3599854-5.1400146c0-27.7200012,0-55.440033,0-83.1600037
				c0-14.7599945,0.0999756-29.5200043,0-44.2799988c-0.0275879-5.392807-0.4118042-10.7778931-1.1500244-16.1199951
				c-1.0671387-8.1382751-4.373291-15.820343-9.5499878-22.1899872c-4.3025513-5.4808044-10.098938-9.5997314-16.6900024-11.8600006
				c-3.5499878-1.1699982-7.0700073-2.4700012-10.7800293-2.4799957c-6,0-12.0599976-0.2400055-17.9199829,0.7200012
				c-5.6529541,1.07901-10.9810181,3.4470215-15.5700073,6.9199982
				c-5.8460083,4.1955566-10.5517578,9.7846527-13.6900024,16.2599945
				c-4.0786743,8.5730743-6.1054688,17.9779663-5.9199829,27.4700012c0.0599976,42.960022,0.0599976,85.9200287,0,128.8800201
				v4.8999939h-63.2700195C676.4099731,327.1700134,676.1599731,21.2299995,676.9199829,15.5500002z'
                />
                <path
                  d='M996.2999878,168.1699982h-57.6199951c-0.4796143-3.9325409-0.2047119-7.9204712,0.8099976-11.75
				c1.1958618-5.3522949,2.8692627-10.5865784,5-15.6399994c2.7290039-5.9702911,6.2727661-11.5332794,10.5300293-16.5299988
				c6.8800049-8.3199997,15.3900146-14.6200027,24.7700195-19.6800003
				c4.987793-2.5397873,10.1958618-4.6216583,15.5599976-6.2200012c5.7631836-1.9746475,11.6619873-3.5287247,17.6500244-4.6500015
				c22.3500366-4.1460037,45.348938-3.1750412,67.2699585,2.8399963c5.598877,1.6560593,11.0509033,3.7733002,16.3000488,6.3300018
				c4.3551025,1.9063339,8.5202637,4.2199478,12.4399414,6.9100037c4.1530762,2.9936905,8.0407715,6.3393784,11.6199951,10
				c6.9775391,6.9157562,12.2409668,15.3674774,15.3699951,24.6800079
				c2.8443604,8.156601,4.3405762,16.7221527,4.4300537,25.3600006c0,51.7000122,0,103.4033508,0,155.1099854
				c0,1.6400146-0.1600342,3.2799988-0.25,5.0799866h-63.6300049V295c-1.2600098,1.0799866-2,1.4400024-2.3199463,2
				c-3.3614502,5.4790344-7.3555908,10.5438232-11.9000244,15.0899963
				c-4.5871582,4.7142334-9.8436279,8.7272949-15.6000977,11.9100037
				c-4.7381592,2.5067749-9.6954346,4.5753479-14.8100586,6.1799927c-11.7700195,4-24,4.519989-36.2199707,3.3699951
				c-9.7888184-0.9163513-19.3494263-3.4967346-28.2700195-7.6300049
				c-7.96875-3.5645142-15.1765747-8.6300354-21.2299194-14.9199829
				c-8.4665527-8.8165283-14.2754517-19.8417053-16.7600098-31.8099976
				c-2.0938721-9.2504883-2.3925781-18.8168945-0.8800049-28.1799927c1-5.8600006,2.1599731-11.7599945,5.4400024-17
				c1.4000244-2.2100067,2.3900146-4.6699982,3.8099976-6.8699951c5.6599121-8.6004944,13.4170532-15.6177216,22.539978-20.3899994
				c4.7843018-2.6832123,9.75354-5.0224609,14.8699951-7c5.0494995-1.8340302,10.2310791-3.2815399,15.5-4.3300018
				c6.0653076-1.3554993,12.2112427-2.320816,18.4000244-2.8899994c15.2800293-1.2100067,30.5999756-2,45.9000244-3
				c6.9300537-0.4499969,13.8499756-1,21.1600342-1.5c0.0999756-0.9499969,0.2900391-2,0.3000488-3
				c0-4.4400024,0.2299805-8.8899994,0-13.3199921c-0.6300049-10.8300018-4.5799561-20.0500031-14.25-25.8000031
				c-5.5668945-3.3354187-11.8527832-5.2866821-18.3299561-5.6900024
				c-7.6899414-0.4799957-15.3399658-0.2700043-22.7800293,2.1199951c-9.5499878,3.0599976-17.5700073,8.0599976-22,17.4499969
				C998.0054932,162.5215302,997.0635986,165.3203735,996.2999878,168.1699982z M1076.4899902,228.1699982
				c-1.4200439,0-2.2399902-0.0500031-3,0c-6.4599609,0.4100037-12.9100342,0.8600006-19.3699951,1.2599945
				c-10.1700439,0.6300049-20.3599854,1-30.5,1.9400024c-7.057251,0.5301666-13.9136353,2.5949249-20.0900269,6.0500031
				c-4.789978,2.75-8.6500244,6.5-10.6500244,11.6499939c-2.2232056,5.4730835-2.6812134,11.503952-1.3099976,17.2500153
				c1.2578125,5.1431274,4.1447144,9.7418213,8.2299805,13.1099854c4.4500122,3.8399963,10,5.5700073,15.5999756,6.8399963
				c7.7605591,1.7036438,15.8012695,1.6763306,23.5500488-0.0799866c4.2115479-0.8796997,8.3250732-2.1760864,12.2800293-3.8699951
				c5.6763916-2.524231,10.7492676-6.2299805,14.8800049-10.8699951c3.6501465-3.9348755,6.4274902-8.5967102,8.1500244-13.6799927
				c1.7982178-5.3479767,2.5849609-10.984024,2.3199463-16.6199951c-0.2399902-4.1500244-0.0899658-8.3300171-0.0899658-12.960022
				V228.1699982z'
                />
                <path
                  d='M2240.8898926,96.3499985H2308c-0.7299805,2.6100006-1.2900391,5-2.0800781,7.3499985
				c-2.1799316,6.4400024-4.4799805,12.8300018-6.6799316,19.2600021
				c-3.2900391,9.5799942-6.6101074,19.1500015-9.8000488,28.7699966c-3.3798828,10.1999969-6.5400391,20.4700012-10,30.6600037
				c-2.5,7.4499969-5.3400879,14.7799988-7.8898926,22.2200012c-1.6999512,5-3.1398926,10-4.7299805,15
				c-0.5700684,1.8099976-1.2600098,3.5800018-1.8898926,5.3699951
				c-3.8601074,11.1699982-7.7800293,22.3199921-11.5600586,33.5099945c-4.0700684,12.1099854-8,24.269989-12,36.3900146
				c-3.5800781,10.8699951-7.0700684,21.769989-10.8400879,32.5700073c-2.8100586,8.0899963-5.5800781,16.2399902-9.1201172,24
				c-5.4599609,12-12.2600098,23.1700134-22.0900879,32.2600098c-6.09375,5.7101135-13.0661621,10.4033203-20.6499023,13.8999939
				c-7.0761719,3.1852112-14.4997559,5.5344238-22.1201172,7c-13.5,2.7000122-27.1499023,3-40.8200684,2.3399963
				c-5.2600098-0.2399902-10.5100098-0.8900146-15.75-1.4700012c-1.8898926-0.2099915-3.7399902-0.75-5.5-1.1199951
				c-0.8701172-3.3999939-0.8500977-43.7799988,0.0600586-47.0700073l3,0.2600098
				c6.1899414,0.5400085,12.3798828,1.3399963,18.5900879,1.519989c6.5993652,0.2722473,13.1777344-0.9089355,19.2700195-3.4599915
				c5.0085449-2.0956421,9.1166992-5.8920288,11.6000977-10.7200012c2.1899414-4.2099915,3.7299805-8.7600098,5.5200195-13.1799927
				c1-2.5400085-0.5800781-4.6600037-1.3000488-6.8599854c-1.9199219-5.7600098-4.0600586-11.4500122-6.0900879-17.1700134
				c-2.380127-6.7466736-4.753418-13.5-7.1201172-20.2600098c-2.1599121-6.1900024-4.2600098-12.3999939-6.4499512-18.5799866
				s-4.5-12.3399963-6.6999512-18.5200043c-2.3601074-6.6499939-4.6201172-13.3199921-7-20
				c-2.6201172-7.4199982-5.3000488-14.8199921-8-22.2299957c-1.4001465-3.9199982-2.8034668-7.8466644-4.2099609-11.7799988
				c-3-8.5299988-6.1499023-17-9.1499023-25.6000061c-2.1298828-6.0700073-4.0500488-12.1999969-6.1799316-18.2599945
				c-2.4499512-7-5.0600586-13.8999939-7.5300293-20.8699951c-4.0800781-11.5733414-8.130127-23.1533356-12.1499023-34.7400055
				c2.5400391-0.9000015,63.5700684-1.2300034,69.8701172-0.4000015
				c16.5200195,60.7500076,32.7700195,121.6800003,49.4299316,182.9100037
				c1.3930664-0.8493958,2.3352051-2.2753906,2.5700684-3.8900146c1.1201172-4.3999939,2.3000488-8.7799988,3.5-13.1600037
				c2.2600098-8.2799988,4.5800781-16.5599976,6.8100586-24.8500061c2.0900879-7.7299957,4.0600586-15.4799957,6.1599121-23.1999969
				c2.2800293-8.4199982,4.6899414-16.8000031,6.9499512-25.2100067c2.1699219-8.0700073,4.1398926-16.1999969,6.3601074-24.2599945
				c2.5900879-9.4199982,5.3798828-18.7899933,8-28.2100067c1.4399414-5.1699982,2.7199707-10.3899994,4.1201172-15.5800018
				c1.5500488-5.7699966,3.1699219-11.5200043,4.6999512-17.2900009
				C2239.8100586,101.3399963,2240.3000488,98.9000015,2240.8898926,96.3499985z'
                />
                <path
                  d='M1790.2099609,213.3399963c0.2800293,9.3600006,0,18.75-1.8000488,27.9400024
				c-1.5999756,7.9199982-3.9300537,15.7200012-6.2099609,23.4899902c-2.4300537,8.269989-6.5899658,15.8399963-11.1800537,23
				c-3.3000488,5.1799927-6.8800049,10.3099976-11.6300049,14.4400024c-3.5899658,3.1199951-6.9399414,6.5-10.5200195,9.6400146
				c-4.8699951,4.2799988-10.5100098,7.3999939-16.2299805,10.3599854
				c-9.9165039,5.0178528-20.5269775,8.5265503-31.4799805,10.4100037c-12.1431885,2.2740784-24.5319824,2.9464722-36.8499756,2
				c-7.6500244-0.6199951-15.2700195-1.2799988-22.7299805-3.2399902
				c-7.0926514-1.6819458-14.0177002-4.0048218-20.6899414-6.9400024
				c-6.7700195-3.1799927-13.4599609-6.6600037-19.2600098-11.4700012c-4.5704346-3.6246948-8.9119873-7.5288391-13-11.6900024
				c-3.8225098-4.1081543-7.2664795-8.5528259-10.2900391-13.2799988c-3.138916-4.9361877-5.8968506-10.1044922-8.25-15.4599915
				c-2.0838623-4.8232117-3.8411865-9.7810669-5.2600098-14.8399963c-1.5500488-5.2700043-2.7099609-10.6499939-3.9300537-16
				c-0.3887939-1.8785248-0.6328125-3.7841187-0.7299805-5.6999969c-0.7099609-10.2700043-2.3499756-20.5-1.2800293-30.8399963
				c0.8000488-7.7400055,1.0799561-15.5899963,2.6099854-23.1800079
				c2.1768799-11.6275482,5.9951172-22.8867493,11.3399658-33.4400024c4.9239502-9.559967,11.2581787-18.3247375,18.7900391-26
				c6.2908936-6.3717346,13.4641113-11.8071976,21.3000488-16.1399994c10.1385498-5.6221924,21.0738525-9.6685257,32.4300537-12
				c12.3066406-2.6925278,24.921875-3.7017441,37.5-3c6.6600342,0.4499969,13.25,1.8700027,19.8800049,2.8000031
				c6.6322021,1.0499344,13.1040039,2.938385,19.2600098,5.6199875c4.8636475,1.9570465,9.6202393,4.1701431,14.25,6.6299973
				c7.1097412,3.9236221,13.6727295,8.7651291,19.5200195,14.4000015
				c6.9421387,6.5141296,12.901123,14.0023422,17.6899414,22.2300034c5.2025146,9.1169128,9.2150879,18.8629608,11.9399414,29
				C1789.0386963,185.5190277,1790.65979,199.4243011,1790.2099609,213.3399963z M1725.9299316,212.8600006
				c-0.1298828-8.5245667-1.057251-17.0182648-2.7700195-25.3699951
				c-1.3100586-6.6987305-3.5166016-13.1904449-6.5600586-19.3000031
				c-3.3699951-6.5899963-7.6899414-12.6600037-13.7199707-17.1600037
				c-2.9008789-2.2932892-6.0792236-4.2117462-9.4599609-5.7100067c-4.1986084-1.7517853-8.6099854-2.9420471-13.1199951-3.5399933
				c-9.4414062-1.2201996-19.029541,0.4976959-27.4599609,4.9199982
				c-6.7355957,3.598938-12.4185791,8.8890076-16.4899902,15.3500061
				c-3.8582764,5.8765564-6.7891846,12.3118744-8.6899414,19.0800018
				c-2.3883057,8.4086304-3.7878418,17.0671234-4.1700439,25.8000031
				c-0.5725098,11.3842316,0.4576416,22.79245,3.0600586,33.8899994c2,8.6900024,5,17,10.1500244,24.3099976
				c8.1500244,11.5899963,18.9200439,18.7600098,33.4100342,19.269989
				c7.6400146,0.269989,15.1899414,0.2999878,22.5799561-2.8299866c8.0604248-3.4254456,14.9650879-9.0977478,19.8900146-16.3399963
				c6.8599854-9.7799988,10-21.0700073,11.7700195-32.6799927c0.9998779-6.4900208,1.0799561-13.1200256,1.5799561-19.6900177
				H1725.9299316z'
                />
                <path
                  d='M435.3299866,258.8099976h61.8600159c0.3891296,0.8441772,0.723175,1.7126465,1,2.6000061
				c2.1199951,9,6.75,16.4400024,14.5200195,21.3899841c5.7353516,3.762085,12.3536987,5.9613037,19.2000122,6.3800049
				c7.9199829,0.4100037,15.8300171,0.6799927,23.6900024-0.6300049c6.8948975-1.0046692,13.3330078-4.0444031,18.4899902-8.730011
				c2.6638794-2.4472961,4.493042-5.6684875,5.2299805-9.2099915c0.8376465-3.9887695,0.7349243-8.1177979-0.2999878-12.0599976
				c-1.25-4.9199982-4.6300049-8.2599945-8.9299927-10.6900024c-7.1699829-4.0599976-15-6.1000061-23-7.7599945
				c-6.789978-1.4100037-13.5499878-3-20.3400269-4.4600067c-5.2600098-1.1199951-10.539978-2.1600037-15.8200073-3.2200012
				c-7.1470947-1.4025574-14.1927185-3.2800598-21.0899963-5.6199951c-7.5739746-2.5576782-14.7841492-6.0871277-21.4500122-10.5
				c-6.4041443-4.2037964-11.977478-9.5533905-16.4400024-15.7799988C443.8099976,189,441,176.1600037,441.3599854,162.2599945
				c0.1263428-6.5885162,1.238678-13.1209717,3.2999878-19.3800049c2.5909424-7.967804,6.8525085-15.2909088,12.5-21.4799957
				c3.2467957-3.6275558,6.8146057-6.9544144,10.6600037-9.9400024c7.4337463-5.4399185,15.6373596-9.7405701,24.3400269-12.7599945
				c11.5700073-4.1900024,23.6499939-6.0699997,35.8100281-7.0800018c11.3504639-0.8834076,22.7659912-0.3965683,34,1.4499969
				c9.9522095,1.495575,19.6885986,4.181488,29,8c8.0684204,3.3162766,15.5663452,7.8784714,22.2199707,13.5200043
				c6.0197754,5.1228333,11.1521606,11.2046661,15.1900024,18c3.1375732,5.2184753,5.4451294,10.8928528,6.8400269,16.8200073
				c1.0700073,4.6499939,1.6799927,9.3999939,2.4000244,14.1199951c0.0897217,1.1382294,0.0897217,2.2817688,0,3.4199982
				c-3.1400146,0.8999939-55.0800171,1-58.6400146,0.1199951c-0.2199707-1.2899933-0.3900146-2.6999969-0.710022-4.0700073
				c-1.3896484-6.481369-4.812561-12.3496857-9.7700195-16.75c-3.2045898-2.8484192-6.906311-5.0823364-10.9199829-6.5899963
				c-9.6375122-3.4767151-20.0515137-4.2072296-30.0800171-2.1100006
				c-7.4699707,1.4400024-14.5200195,4.1900024-19.4200134,10.4700012c-6,7.6900024-6.8099976,18.4199982-0.0899963,26.1199951
				c2.3753357,2.6520233,5.2501221,4.8098297,8.460022,6.3500061c6.2875977,2.9326935,12.9615479,4.953064,19.8200073,6
				c3.5300293,0.5899963,7,1.5500031,10.4899902,2.3300018c3.7299805,0.8399963,7.4799805,1.6300049,11.2000122,2.5099945
				c9.8699951,2.3300018,19.8900146,3.9299927,29.6699829,6.75c7.2531738,1.9754333,14.3521118,4.478775,21.2399902,7.4900055
				c6.7975464,2.9707031,13.0687866,7.0232086,18.5700073,12c4.4102783,4.0302734,8.0230713,8.8541412,10.6500244,14.2200012
				c6.6500244,13.8300018,6.3800049,28.280014,3.9099731,42.8799896c-1.1900024,7-4.2700195,13.3399963-7.9199829,19.4299927
				c-5.0758667,8.2554016-11.8156738,15.3630066-19.789978,20.8699951
				c-6.0515747,4.2489929-12.5750732,7.7825623-19.4400024,10.5299988
				c-6.4626465,2.4828186-13.0841064,4.5307007-19.8200073,6.1300049c-3.4718018,0.9298096-7.0083618,1.5983276-10.5800171,2
				c-9.7700195,1.0499878-19.5900269,2-29.4299927,1.4200134c-7.7757568-0.4016418-15.5238647-1.2261963-23.2099915-2.4700012
				c-7.2891541-1.2735291-14.4776001-3.0681458-21.5100098-5.3699951c-5.08255-1.7114258-9.9885254-3.9082336-14.6499939-6.5599976
				C470.4120483,317.7900085,465.499939,314.3699951,461,310.4500122c-10.3399963-9.1000061-18-20-21.8299866-33.4299927
				c-1.3699951-4.8200073-2.4899902-9.7200012-3.6400146-14.6099854
				C435.3668823,261.2173157,435.2999878,260.0134277,435.3299866,258.8099976z'
                />
                <path
                  d='M32.4900017,144.5500031H0V96.2300034h32.2700005c0.4399986-4.8899994,0.1599998-9.5299988,0.2099991-14.159996
				c0.0499992-4.7900009,0-9.5899963,0-14.3899994V39.4700012h64.3000031V96H139v48.3899994H97
				c-0.1200027,1.3200073-0.3000031,2.3399963-0.3000031,3.3699951c0,36.8300018-0.0699997,73.6600037,0.0599976,110.4900055
				c-0.1386642,4.9242859,0.6134109,9.8330994,2.2200012,14.4899902c3.2600021,8.6400146,10,12.6600037,18.5899963,13.480011
				c6.4100037,0.6199951,12.9300079,0,19.3999939,0h2v46.3099976c-1.8039093,0.4320068-3.6299286,0.7658386-5.4700012,1
				c-9.9100037,0.8599854-19.8199997,1.8200073-29.7900009,1.4500122
				c-12.4899979-0.4700012-24.8899994-1.7200012-36.5800018-6.4400024
				c-10.1399994-4.1000061-18.6200027-10.4200134-24.5499992-19.8600159C38.4000015,302,36,294.6300049,34.4300003,286.7999878
				c-1.402771-7.4129028-2.0726662-14.9458618-2-22.4899902c0-38.4000092,0-76.793335,0-115.1799927L32.4900017,144.5500031z'
                />
                <path
                  d='M174.6999969,330V96.7200012c2.75-0.7099991,59.1100006-0.8899994,63.6900024-0.1699982v40l0.8899994,0.2100067
				c0.5390472-0.9547729,0.9844513-1.9594574,1.3300018-3c2.0439911-8.484787,5.9063416-16.4244308,11.3199921-23.2700043
				c6.6789856-8.647377,16.1083984-14.7572632,26.730011-17.3199997c6.1457825-1.6465988,12.5252075-2.2449875,18.8699951-1.7699966
				c4.3900146,0.4000015,8.730011,1.3899994,13,2.0999985c0.8800049,2.7600021,1.1300049,50.890007,0.3200073,55.7000046
				c-0.8900146-0.1000061-1.7999878-0.1799927-2.7000122-0.3200073c-4.3699951-0.6999969-8.7099915-1.6300049-13.1000061-2.1000061
				c-9.9256592-1.211853-19.9968872,0.0595093-29.3099976,3.6999969c-6.023407,2.3468628-11.3553925,6.1790161-15.5,11.1399994
				c-4.6772461,5.7626801-7.9930267,12.507019-9.6999969,19.7299957c-1.484436,6.0523376-2.1934509,12.2688293-2.1100006,18.5
				c0,41.7533264,0,83.5066681,0,125.2599945V330H174.6999969z'
                />
                <path
                  d='M338.8699951,329.9400024V96.7699966C341.4500122,96,396,95.6900024,402,96.4000015
				c0.6900024,2.5800018,0.8399963,229.2900085,0.1099854,233.5400085H338.8699951z'
                />
                <path
                  d='M404.4200134,34.8899994c0.5899963,8-3.6199951,15.7200012-10.0899963,22.0599976
				c-4.5775146,4.4256935-10.3712158,7.3852234-16.6400146,8.5c-6.2548218,1.2135391-12.7181396,0.7723083-18.75-1.2799988
				c-6.8857117-2.2100487-12.7960205-6.7319679-16.730011-12.7999992c-4.1099854-6.2299995-6.3599854-13.079998-5.769989-20.6900005
				c0.2964783-3.3278599,1.0955505-6.5915775,2.3699951-9.6799984c2.4032593-6.1316376,6.5815125-11.4066801,12-15.1499996
				C355.7355652,2.3993127,361.5338135,0.406486,367.5400085,0.1C380-0.7,390.3599854,3.24,398,13.3100004
				C403,19.7900009,404.5700073,25.0799999,404.4200134,34.8899994z'
                />
              </g>
            </g>
          </g>

          <g
            id='Layer_2_00000079473966681312878150000008130550272874370465_'
            data-name='Layer 2'
            clip-path='url(#clip-path)'
            fill='red'
            className='last-tm'
            // opacity='0'
          >
            <g id='Layer_1-2'>
              <g>
                <path
                  d='M1526.8100586,330h-63.4100342c-0.0799561-1.8099976-0.2099609-3.4500122-0.2099609-5.0899963
				c0-45.8333435,0-91.6666718,0-137.5c0.0875244-6.5981903-0.8192139-13.171936-2.6899414-19.5
				c-3-9.6000061-9-16.6300049-18.4599609-20.5599976c-9.1300049-3.8000031-18.6500244-3.8000031-27.9300537-1.7700043
				c-8.8679199,1.9668121-16.5993652,7.3583374-21.5100098,15c-5.088623,7.6015778-7.7397461,16.5734711-7.6000977,25.7200012
				c0,46.1933289,0,92.383316,0,138.5699921c0,1.6400146-0.1500244,3.2799988-0.2399902,5.1199951h-61.0999756
				c-0.0699463-1.4599915-0.1999512-3-0.1999512-4.4599915c0-41.1466675,0-82.3000031,0-123.4599915
				c0-5-0.1500244-10.0800018,0-15.1100006c0.2235107-5.3820953-0.3151855-10.7687531-1.5999756-16
				c-2.7900391-11.2100067-9.1600342-19.3999939-20.1999512-23.7700043c-8.8499756-3.5-17.9300537-3.4199982-26.8599854-1.5500031
				c-6.793335,1.5662079-12.9411621,5.1828766-17.6102295,10.3600006c-6,6.3600006-9.1800537,14-11,22.4599915
				c-0.7598877,3.8753662-1.0952148,7.821991-1,11.7700043V330h-62.8800049
				c-0.7600098-2.5700073-1-227.8300018-0.2800293-233.5899963h63.1400146v38.7599945l0.6999512,0.2299957
				c0.5406494-0.4972992,1.0025635-1.0738831,1.3699951-1.7100067c3.4147949-8.9544067,8.7657471-17.0438232,15.670166-23.6899872
				c4.8123779-4.6363373,10.3439941-8.4625473,16.3800049-11.3300018
				c12.3405762-6.015007,26.1064453-8.4933395,39.7700195-7.1600037
				c13.6500244,1.3199997,26.4499512,5.4499969,37.1099854,14.6399994
				c4.5775146,3.8200226,8.5109863,8.351181,11.6500244,13.4199982c3.6668701,5.8577042,6.4532471,12.2223206,8.2700195,18.8899994
				c0.1906738,0.4581451,0.4250488,0.8968506,0.6999512,1.3099976c0.5739746-0.5723419,1.0654297-1.2219391,1.4599609-1.9299927
				c1.9450684-5.2593384,4.581543-10.2363815,7.8399658-14.8000031
				c9.0424805-12.7390137,21.9023438-22.271843,36.7199707-27.2200012c11.5699463-3.8799973,23.5699463-5.6500015,35.7700195-4.25
				c11.2199707,1.2799988,22.1300049,4,32,9.7900009c8.2915039,4.7622299,15.5064697,11.1906662,21.1899414,18.8799973
				c4.9282227,6.8850861,8.7602539,14.4919128,11.3599854,22.5500031c0.7210693,2.5334167,1.3051758,5.1038055,1.75,7.6999969
				c1.4476318,6.5680695,2.175293,13.2743073,2.1700439,20c0.0400391,51.7133331,0.0400391,103.4233398,0,155.1300049
				C1527.0699463,327,1526.9100342,328.3999939,1526.8100586,330z'
                />
                <path
                  d='M1983.1700439,136.0800018c0.0899658-6.6399994,0-13.2900009,0-19.9300003s0-13.4300003,0-20.1500015V15.5799999
				h63.6999512V330h-63.5999756c0-4.269989-0.0799561-8.5400085-0.0899658-12.8099976c0-4.4400024,0-8.8800049,0-13.3200073
				v-13.4100037c-1.5325928,0.980957-2.6993408,2.4394531-3.3199463,4.1499939
				c-4.2319336,8.5864258-10.2174072,16.190033-17.5699463,22.3200073c-12,10.1799927-25.9399414,15.6799927-41.5699463,17.480011
				c-9.7851562,1.0449219-19.6685791,0.708313-29.3599854-1c-8.0100098-1.4439392-15.7611084-4.0692444-23-7.7900085
				c-9.2861328-4.6867065-17.6154785-11.0660095-24.5600586-18.8099976
				c-4.9748535-5.6221619-9.2741699-11.8076477-12.8100586-18.4299927
				c-2.3834229-4.6491394-4.451416-9.4533081-6.1900635-14.3800049
				c-4.3996582-11.6364746-7.2116699-23.8126526-8.3599854-36.1999969c-1.0487061-9.0450592-1.5595703-18.1443939-1.5300293-27.25
				c0.0969238-12.3430328,1.3017578-24.6524353,3.5999756-36.7799988
				c2.3178711-12.3393707,6.463501-24.2639313,12.3000488-35.3800049
				c4.213623-8.0336609,9.5655518-15.4165649,15.8900146-21.9199982c4.2144775-4.3441162,8.9116211-8.1924133,14-11.4700012
				c7.3170166-4.7318726,15.3554688-8.2410965,23.8000488-10.3899994c10.0699463-2.4700012,20.2299805-4,30.5999756-3.1999969
				c5.1245117,0.4585419,10.2169189,1.2232437,15.25,2.2900009c6.5479736,1.3862,12.8513184,3.7457352,18.6999512,7
				c10.5999756,5.5941772,19.5919189,13.8081131,26.1199951,23.8600006c2.1199951,3.3099976,4,6.7600021,6,10.1299973
				c0.3592529,0.5316925,0.756958,1.036377,1.1899414,1.5099945L1983.1700439,136.0800018z M1879.7600098,215.4100037
				c0.0449219,4.4746857,0.3220215,8.9440155,0.8299561,13.3899994c0.7817383,5.3181305,1.9890137,10.5649719,3.6099854,15.6900024
				c2.4124756,8.2691803,6.6036377,15.9109344,12.2800293,22.3899994c4.4078369,5.0044861,9.8730469,8.9667053,16,11.6000061
				c9.4200439,4,19.3399658,4.5799866,29.2600098,2.6300049c11.0600586-2.1700134,20-8.1400146,27-17
				c7.6378174-9.9130554,12.3581543-21.7606049,13.6300049-34.2099915c1.6800537-14.4199982,1.8499756-28.8899994-2-43.1600037
				c-3.2700195-12.1600037-8.8000488-22.8899994-18.3399658-31.3300018c-5.4716797-5.064209-12.2320557-8.5240021-19.5400391-10
				c-9.3299561-1.6900024-18.7299805-1.6900024-28,1.7100067c-8.6690674,3.201767-16.1624756,8.9587097-21.4899902,16.5099792
				c-5.2399902,7.1799927-8.4399414,15.4299927-10.5100098,24.1000061
				C1880.4699707,196.0500031,1879.7600098,204.5,1879.7600098,215.4100037z'
                />
                <path
                  d='M676.9199829,15.5500002h63.5700073v30.1199989c0,10.0800018,0,20.159996,0,30.2399979
				c0,9.9599991,0,19.9199982,0,29.8799973s0,19.9599991,0,30l1,0.2799988c0.460022-0.6900024,1-1.3600006,1.3699951-2.0800018
				c2.3699951-4.1199951,4.4899902-8.4100037,7.1199951-12.3600006c3.4869995-5.1042099,7.6746826-9.6925049,12.4400024-13.6299896
				c6.2246704-5.2093811,13.3669434-9.211235,21.0599976-11.8000031C794.2352905,92.4305496,805.6157227,90.7669449,817,91.3000031
				c13.2999878,0.6500015,26,3.5,37.8099976,10c8.5597534,4.7560883,16.048584,11.2237015,22,19
				c7.710022,9.8699951,12.3099976,21.0899963,15.4199829,33.0699921
				c2.5100098,9.7100067,3.1400146,19.6300049,3.1500244,29.5800018c0.0665894,47.519989,0.0665894,95.0366669,0,142.5500031v4.5
				h-63.3200073c-0.1199951-1.6799927-0.3599854-3.4100037-0.3599854-5.1400146c0-27.7200012,0-55.440033,0-83.1600037
				c0-14.7599945,0.0999756-29.5200043,0-44.2799988c-0.0275879-5.392807-0.4118042-10.7778931-1.1500244-16.1199951
				c-1.0671387-8.1382751-4.373291-15.820343-9.5499878-22.1899872c-4.3025513-5.4808044-10.098938-9.5997314-16.6900024-11.8600006
				c-3.5499878-1.1699982-7.0700073-2.4700012-10.7800293-2.4799957c-6,0-12.0599976-0.2400055-17.9199829,0.7200012
				c-5.6529541,1.07901-10.9810181,3.4470215-15.5700073,6.9199982
				c-5.8460083,4.1955566-10.5517578,9.7846527-13.6900024,16.2599945
				c-4.0786743,8.5730743-6.1054688,17.9779663-5.9199829,27.4700012c0.0599976,42.960022,0.0599976,85.9200287,0,128.8800201
				v4.8999939h-63.2700195C676.4099731,327.1700134,676.1599731,21.2299995,676.9199829,15.5500002z'
                />
                <path
                  d='M996.2999878,168.1699982h-57.6199951c-0.4796143-3.9325409-0.2047119-7.9204712,0.8099976-11.75
				c1.1958618-5.3522949,2.8692627-10.5865784,5-15.6399994c2.7290039-5.9702911,6.2727661-11.5332794,10.5300293-16.5299988
				c6.8800049-8.3199997,15.3900146-14.6200027,24.7700195-19.6800003
				c4.987793-2.5397873,10.1958618-4.6216583,15.5599976-6.2200012c5.7631836-1.9746475,11.6619873-3.5287247,17.6500244-4.6500015
				c22.3500366-4.1460037,45.348938-3.1750412,67.2699585,2.8399963c5.598877,1.6560593,11.0509033,3.7733002,16.3000488,6.3300018
				c4.3551025,1.9063339,8.5202637,4.2199478,12.4399414,6.9100037c4.1530762,2.9936905,8.0407715,6.3393784,11.6199951,10
				c6.9775391,6.9157562,12.2409668,15.3674774,15.3699951,24.6800079
				c2.8443604,8.156601,4.3405762,16.7221527,4.4300537,25.3600006c0,51.7000122,0,103.4033508,0,155.1099854
				c0,1.6400146-0.1600342,3.2799988-0.25,5.0799866h-63.6300049V295c-1.2600098,1.0799866-2,1.4400024-2.3199463,2
				c-3.3614502,5.4790344-7.3555908,10.5438232-11.9000244,15.0899963
				c-4.5871582,4.7142334-9.8436279,8.7272949-15.6000977,11.9100037
				c-4.7381592,2.5067749-9.6954346,4.5753479-14.8100586,6.1799927c-11.7700195,4-24,4.519989-36.2199707,3.3699951
				c-9.7888184-0.9163513-19.3494263-3.4967346-28.2700195-7.6300049
				c-7.96875-3.5645142-15.1765747-8.6300354-21.2299194-14.9199829
				c-8.4665527-8.8165283-14.2754517-19.8417053-16.7600098-31.8099976
				c-2.0938721-9.2504883-2.3925781-18.8168945-0.8800049-28.1799927c1-5.8600006,2.1599731-11.7599945,5.4400024-17
				c1.4000244-2.2100067,2.3900146-4.6699982,3.8099976-6.8699951c5.6599121-8.6004944,13.4170532-15.6177216,22.539978-20.3899994
				c4.7843018-2.6832123,9.75354-5.0224609,14.8699951-7c5.0494995-1.8340302,10.2310791-3.2815399,15.5-4.3300018
				c6.0653076-1.3554993,12.2112427-2.320816,18.4000244-2.8899994c15.2800293-1.2100067,30.5999756-2,45.9000244-3
				c6.9300537-0.4499969,13.8499756-1,21.1600342-1.5c0.0999756-0.9499969,0.2900391-2,0.3000488-3
				c0-4.4400024,0.2299805-8.8899994,0-13.3199921c-0.6300049-10.8300018-4.5799561-20.0500031-14.25-25.8000031
				c-5.5668945-3.3354187-11.8527832-5.2866821-18.3299561-5.6900024
				c-7.6899414-0.4799957-15.3399658-0.2700043-22.7800293,2.1199951c-9.5499878,3.0599976-17.5700073,8.0599976-22,17.4499969
				C998.0054932,162.5215302,997.0635986,165.3203735,996.2999878,168.1699982z M1076.4899902,228.1699982
				c-1.4200439,0-2.2399902-0.0500031-3,0c-6.4599609,0.4100037-12.9100342,0.8600006-19.3699951,1.2599945
				c-10.1700439,0.6300049-20.3599854,1-30.5,1.9400024c-7.057251,0.5301666-13.9136353,2.5949249-20.0900269,6.0500031
				c-4.789978,2.75-8.6500244,6.5-10.6500244,11.6499939c-2.2232056,5.4730835-2.6812134,11.503952-1.3099976,17.2500153
				c1.2578125,5.1431274,4.1447144,9.7418213,8.2299805,13.1099854c4.4500122,3.8399963,10,5.5700073,15.5999756,6.8399963
				c7.7605591,1.7036438,15.8012695,1.6763306,23.5500488-0.0799866c4.2115479-0.8796997,8.3250732-2.1760864,12.2800293-3.8699951
				c5.6763916-2.524231,10.7492676-6.2299805,14.8800049-10.8699951c3.6501465-3.9348755,6.4274902-8.5967102,8.1500244-13.6799927
				c1.7982178-5.3479767,2.5849609-10.984024,2.3199463-16.6199951c-0.2399902-4.1500244-0.0899658-8.3300171-0.0899658-12.960022
				V228.1699982z'
                />
                <path
                  d='M2240.8898926,96.3499985H2308c-0.7299805,2.6100006-1.2900391,5-2.0800781,7.3499985
				c-2.1799316,6.4400024-4.4799805,12.8300018-6.6799316,19.2600021
				c-3.2900391,9.5799942-6.6101074,19.1500015-9.8000488,28.7699966c-3.3798828,10.1999969-6.5400391,20.4700012-10,30.6600037
				c-2.5,7.4499969-5.3400879,14.7799988-7.8898926,22.2200012c-1.6999512,5-3.1398926,10-4.7299805,15
				c-0.5700684,1.8099976-1.2600098,3.5800018-1.8898926,5.3699951
				c-3.8601074,11.1699982-7.7800293,22.3199921-11.5600586,33.5099945c-4.0700684,12.1099854-8,24.269989-12,36.3900146
				c-3.5800781,10.8699951-7.0700684,21.769989-10.8400879,32.5700073c-2.8100586,8.0899963-5.5800781,16.2399902-9.1201172,24
				c-5.4599609,12-12.2600098,23.1700134-22.0900879,32.2600098c-6.09375,5.7101135-13.0661621,10.4033203-20.6499023,13.8999939
				c-7.0761719,3.1852112-14.4997559,5.5344238-22.1201172,7c-13.5,2.7000122-27.1499023,3-40.8200684,2.3399963
				c-5.2600098-0.2399902-10.5100098-0.8900146-15.75-1.4700012c-1.8898926-0.2099915-3.7399902-0.75-5.5-1.1199951
				c-0.8701172-3.3999939-0.8500977-43.7799988,0.0600586-47.0700073l3,0.2600098
				c6.1899414,0.5400085,12.3798828,1.3399963,18.5900879,1.519989c6.5993652,0.2722473,13.1777344-0.9089355,19.2700195-3.4599915
				c5.0085449-2.0956421,9.1166992-5.8920288,11.6000977-10.7200012c2.1899414-4.2099915,3.7299805-8.7600098,5.5200195-13.1799927
				c1-2.5400085-0.5800781-4.6600037-1.3000488-6.8599854c-1.9199219-5.7600098-4.0600586-11.4500122-6.0900879-17.1700134
				c-2.380127-6.7466736-4.753418-13.5-7.1201172-20.2600098c-2.1599121-6.1900024-4.2600098-12.3999939-6.4499512-18.5799866
				s-4.5-12.3399963-6.6999512-18.5200043c-2.3601074-6.6499939-4.6201172-13.3199921-7-20
				c-2.6201172-7.4199982-5.3000488-14.8199921-8-22.2299957c-1.4001465-3.9199982-2.8034668-7.8466644-4.2099609-11.7799988
				c-3-8.5299988-6.1499023-17-9.1499023-25.6000061c-2.1298828-6.0700073-4.0500488-12.1999969-6.1799316-18.2599945
				c-2.4499512-7-5.0600586-13.8999939-7.5300293-20.8699951c-4.0800781-11.5733414-8.130127-23.1533356-12.1499023-34.7400055
				c2.5400391-0.9000015,63.5700684-1.2300034,69.8701172-0.4000015
				c16.5200195,60.7500076,32.7700195,121.6800003,49.4299316,182.9100037
				c1.3930664-0.8493958,2.3352051-2.2753906,2.5700684-3.8900146c1.1201172-4.3999939,2.3000488-8.7799988,3.5-13.1600037
				c2.2600098-8.2799988,4.5800781-16.5599976,6.8100586-24.8500061c2.0900879-7.7299957,4.0600586-15.4799957,6.1599121-23.1999969
				c2.2800293-8.4199982,4.6899414-16.8000031,6.9499512-25.2100067c2.1699219-8.0700073,4.1398926-16.1999969,6.3601074-24.2599945
				c2.5900879-9.4199982,5.3798828-18.7899933,8-28.2100067c1.4399414-5.1699982,2.7199707-10.3899994,4.1201172-15.5800018
				c1.5500488-5.7699966,3.1699219-11.5200043,4.6999512-17.2900009
				C2239.8100586,101.3399963,2240.3000488,98.9000015,2240.8898926,96.3499985z'
                />
                <path
                  d='M1790.2099609,213.3399963c0.2800293,9.3600006,0,18.75-1.8000488,27.9400024
				c-1.5999756,7.9199982-3.9300537,15.7200012-6.2099609,23.4899902c-2.4300537,8.269989-6.5899658,15.8399963-11.1800537,23
				c-3.3000488,5.1799927-6.8800049,10.3099976-11.6300049,14.4400024c-3.5899658,3.1199951-6.9399414,6.5-10.5200195,9.6400146
				c-4.8699951,4.2799988-10.5100098,7.3999939-16.2299805,10.3599854
				c-9.9165039,5.0178528-20.5269775,8.5265503-31.4799805,10.4100037c-12.1431885,2.2740784-24.5319824,2.9464722-36.8499756,2
				c-7.6500244-0.6199951-15.2700195-1.2799988-22.7299805-3.2399902
				c-7.0926514-1.6819458-14.0177002-4.0048218-20.6899414-6.9400024
				c-6.7700195-3.1799927-13.4599609-6.6600037-19.2600098-11.4700012c-4.5704346-3.6246948-8.9119873-7.5288391-13-11.6900024
				c-3.8225098-4.1081543-7.2664795-8.5528259-10.2900391-13.2799988c-3.138916-4.9361877-5.8968506-10.1044922-8.25-15.4599915
				c-2.0838623-4.8232117-3.8411865-9.7810669-5.2600098-14.8399963c-1.5500488-5.2700043-2.7099609-10.6499939-3.9300537-16
				c-0.3887939-1.8785248-0.6328125-3.7841187-0.7299805-5.6999969c-0.7099609-10.2700043-2.3499756-20.5-1.2800293-30.8399963
				c0.8000488-7.7400055,1.0799561-15.5899963,2.6099854-23.1800079
				c2.1768799-11.6275482,5.9951172-22.8867493,11.3399658-33.4400024c4.9239502-9.559967,11.2581787-18.3247375,18.7900391-26
				c6.2908936-6.3717346,13.4641113-11.8071976,21.3000488-16.1399994c10.1385498-5.6221924,21.0738525-9.6685257,32.4300537-12
				c12.3066406-2.6925278,24.921875-3.7017441,37.5-3c6.6600342,0.4499969,13.25,1.8700027,19.8800049,2.8000031
				c6.6322021,1.0499344,13.1040039,2.938385,19.2600098,5.6199875c4.8636475,1.9570465,9.6202393,4.1701431,14.25,6.6299973
				c7.1097412,3.9236221,13.6727295,8.7651291,19.5200195,14.4000015
				c6.9421387,6.5141296,12.901123,14.0023422,17.6899414,22.2300034c5.2025146,9.1169128,9.2150879,18.8629608,11.9399414,29
				C1789.0386963,185.5190277,1790.65979,199.4243011,1790.2099609,213.3399963z M1725.9299316,212.8600006
				c-0.1298828-8.5245667-1.057251-17.0182648-2.7700195-25.3699951
				c-1.3100586-6.6987305-3.5166016-13.1904449-6.5600586-19.3000031
				c-3.3699951-6.5899963-7.6899414-12.6600037-13.7199707-17.1600037
				c-2.9008789-2.2932892-6.0792236-4.2117462-9.4599609-5.7100067c-4.1986084-1.7517853-8.6099854-2.9420471-13.1199951-3.5399933
				c-9.4414062-1.2201996-19.029541,0.4976959-27.4599609,4.9199982
				c-6.7355957,3.598938-12.4185791,8.8890076-16.4899902,15.3500061
				c-3.8582764,5.8765564-6.7891846,12.3118744-8.6899414,19.0800018
				c-2.3883057,8.4086304-3.7878418,17.0671234-4.1700439,25.8000031
				c-0.5725098,11.3842316,0.4576416,22.79245,3.0600586,33.8899994c2,8.6900024,5,17,10.1500244,24.3099976
				c8.1500244,11.5899963,18.9200439,18.7600098,33.4100342,19.269989
				c7.6400146,0.269989,15.1899414,0.2999878,22.5799561-2.8299866c8.0604248-3.4254456,14.9650879-9.0977478,19.8900146-16.3399963
				c6.8599854-9.7799988,10-21.0700073,11.7700195-32.6799927c0.9998779-6.4900208,1.0799561-13.1200256,1.5799561-19.6900177
				H1725.9299316z'
                />
                <path
                  d='M435.3299866,258.8099976h61.8600159c0.3891296,0.8441772,0.723175,1.7126465,1,2.6000061
				c2.1199951,9,6.75,16.4400024,14.5200195,21.3899841c5.7353516,3.762085,12.3536987,5.9613037,19.2000122,6.3800049
				c7.9199829,0.4100037,15.8300171,0.6799927,23.6900024-0.6300049c6.8948975-1.0046692,13.3330078-4.0444031,18.4899902-8.730011
				c2.6638794-2.4472961,4.493042-5.6684875,5.2299805-9.2099915c0.8376465-3.9887695,0.7349243-8.1177979-0.2999878-12.0599976
				c-1.25-4.9199982-4.6300049-8.2599945-8.9299927-10.6900024c-7.1699829-4.0599976-15-6.1000061-23-7.7599945
				c-6.789978-1.4100037-13.5499878-3-20.3400269-4.4600067c-5.2600098-1.1199951-10.539978-2.1600037-15.8200073-3.2200012
				c-7.1470947-1.4025574-14.1927185-3.2800598-21.0899963-5.6199951c-7.5739746-2.5576782-14.7841492-6.0871277-21.4500122-10.5
				c-6.4041443-4.2037964-11.977478-9.5533905-16.4400024-15.7799988C443.8099976,189,441,176.1600037,441.3599854,162.2599945
				c0.1263428-6.5885162,1.238678-13.1209717,3.2999878-19.3800049c2.5909424-7.967804,6.8525085-15.2909088,12.5-21.4799957
				c3.2467957-3.6275558,6.8146057-6.9544144,10.6600037-9.9400024c7.4337463-5.4399185,15.6373596-9.7405701,24.3400269-12.7599945
				c11.5700073-4.1900024,23.6499939-6.0699997,35.8100281-7.0800018c11.3504639-0.8834076,22.7659912-0.3965683,34,1.4499969
				c9.9522095,1.495575,19.6885986,4.181488,29,8c8.0684204,3.3162766,15.5663452,7.8784714,22.2199707,13.5200043
				c6.0197754,5.1228333,11.1521606,11.2046661,15.1900024,18c3.1375732,5.2184753,5.4451294,10.8928528,6.8400269,16.8200073
				c1.0700073,4.6499939,1.6799927,9.3999939,2.4000244,14.1199951c0.0897217,1.1382294,0.0897217,2.2817688,0,3.4199982
				c-3.1400146,0.8999939-55.0800171,1-58.6400146,0.1199951c-0.2199707-1.2899933-0.3900146-2.6999969-0.710022-4.0700073
				c-1.3896484-6.481369-4.812561-12.3496857-9.7700195-16.75c-3.2045898-2.8484192-6.906311-5.0823364-10.9199829-6.5899963
				c-9.6375122-3.4767151-20.0515137-4.2072296-30.0800171-2.1100006
				c-7.4699707,1.4400024-14.5200195,4.1900024-19.4200134,10.4700012c-6,7.6900024-6.8099976,18.4199982-0.0899963,26.1199951
				c2.3753357,2.6520233,5.2501221,4.8098297,8.460022,6.3500061c6.2875977,2.9326935,12.9615479,4.953064,19.8200073,6
				c3.5300293,0.5899963,7,1.5500031,10.4899902,2.3300018c3.7299805,0.8399963,7.4799805,1.6300049,11.2000122,2.5099945
				c9.8699951,2.3300018,19.8900146,3.9299927,29.6699829,6.75c7.2531738,1.9754333,14.3521118,4.478775,21.2399902,7.4900055
				c6.7975464,2.9707031,13.0687866,7.0232086,18.5700073,12c4.4102783,4.0302734,8.0230713,8.8541412,10.6500244,14.2200012
				c6.6500244,13.8300018,6.3800049,28.280014,3.9099731,42.8799896c-1.1900024,7-4.2700195,13.3399963-7.9199829,19.4299927
				c-5.0758667,8.2554016-11.8156738,15.3630066-19.789978,20.8699951
				c-6.0515747,4.2489929-12.5750732,7.7825623-19.4400024,10.5299988
				c-6.4626465,2.4828186-13.0841064,4.5307007-19.8200073,6.1300049c-3.4718018,0.9298096-7.0083618,1.5983276-10.5800171,2
				c-9.7700195,1.0499878-19.5900269,2-29.4299927,1.4200134c-7.7757568-0.4016418-15.5238647-1.2261963-23.2099915-2.4700012
				c-7.2891541-1.2735291-14.4776001-3.0681458-21.5100098-5.3699951c-5.08255-1.7114258-9.9885254-3.9082336-14.6499939-6.5599976
				C470.4120483,317.7900085,465.499939,314.3699951,461,310.4500122c-10.3399963-9.1000061-18-20-21.8299866-33.4299927
				c-1.3699951-4.8200073-2.4899902-9.7200012-3.6400146-14.6099854
				C435.3668823,261.2173157,435.2999878,260.0134277,435.3299866,258.8099976z'
                />
                <path
                  d='M32.4900017,144.5500031H0V96.2300034h32.2700005c0.4399986-4.8899994,0.1599998-9.5299988,0.2099991-14.159996
				c0.0499992-4.7900009,0-9.5899963,0-14.3899994V39.4700012h64.3000031V96H139v48.3899994H97
				c-0.1200027,1.3200073-0.3000031,2.3399963-0.3000031,3.3699951c0,36.8300018-0.0699997,73.6600037,0.0599976,110.4900055
				c-0.1386642,4.9242859,0.6134109,9.8330994,2.2200012,14.4899902c3.2600021,8.6400146,10,12.6600037,18.5899963,13.480011
				c6.4100037,0.6199951,12.9300079,0,19.3999939,0h2v46.3099976c-1.8039093,0.4320068-3.6299286,0.7658386-5.4700012,1
				c-9.9100037,0.8599854-19.8199997,1.8200073-29.7900009,1.4500122
				c-12.4899979-0.4700012-24.8899994-1.7200012-36.5800018-6.4400024
				c-10.1399994-4.1000061-18.6200027-10.4200134-24.5499992-19.8600159C38.4000015,302,36,294.6300049,34.4300003,286.7999878
				c-1.402771-7.4129028-2.0726662-14.9458618-2-22.4899902c0-38.4000092,0-76.793335,0-115.1799927L32.4900017,144.5500031z'
                />
                <path
                  d='M174.6999969,330V96.7200012c2.75-0.7099991,59.1100006-0.8899994,63.6900024-0.1699982v40l0.8899994,0.2100067
				c0.5390472-0.9547729,0.9844513-1.9594574,1.3300018-3c2.0439911-8.484787,5.9063416-16.4244308,11.3199921-23.2700043
				c6.6789856-8.647377,16.1083984-14.7572632,26.730011-17.3199997c6.1457825-1.6465988,12.5252075-2.2449875,18.8699951-1.7699966
				c4.3900146,0.4000015,8.730011,1.3899994,13,2.0999985c0.8800049,2.7600021,1.1300049,50.890007,0.3200073,55.7000046
				c-0.8900146-0.1000061-1.7999878-0.1799927-2.7000122-0.3200073c-4.3699951-0.6999969-8.7099915-1.6300049-13.1000061-2.1000061
				c-9.9256592-1.211853-19.9968872,0.0595093-29.3099976,3.6999969c-6.023407,2.3468628-11.3553925,6.1790161-15.5,11.1399994
				c-4.6772461,5.7626801-7.9930267,12.507019-9.6999969,19.7299957c-1.484436,6.0523376-2.1934509,12.2688293-2.1100006,18.5
				c0,41.7533264,0,83.5066681,0,125.2599945V330H174.6999969z'
                />
                <path
                  d='M338.8699951,329.9400024V96.7699966C341.4500122,96,396,95.6900024,402,96.4000015
				c0.6900024,2.5800018,0.8399963,229.2900085,0.1099854,233.5400085H338.8699951z'
                />
                <path
                  d='M404.4200134,34.8899994c0.5899963,8-3.6199951,15.7200012-10.0899963,22.0599976
				c-4.5775146,4.4256935-10.3712158,7.3852234-16.6400146,8.5c-6.2548218,1.2135391-12.7181396,0.7723083-18.75-1.2799988
				c-6.8857117-2.2100487-12.7960205-6.7319679-16.730011-12.7999992c-4.1099854-6.2299995-6.3599854-13.079998-5.769989-20.6900005
				c0.2964783-3.3278599,1.0955505-6.5915775,2.3699951-9.6799984c2.4032593-6.1316376,6.5815125-11.4066801,12-15.1499996
				C355.7355652,2.3993127,361.5338135,0.406486,367.5400085,0.1C380-0.7,390.3599854,3.24,398,13.3100004
				C403,19.7900009,404.5700073,25.0799999,404.4200134,34.8899994z'
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </>
  )
}

export default Loader2
